"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalStorageStore = void 0;
/**
 * Copyright 2019, Optimizely
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var js_sdk_utils_1 = require("@optimizely/js-sdk-utils");
var js_sdk_logging_1 = require("@optimizely/js-sdk-logging");
var logger = js_sdk_logging_1.getLogger('EventProcessor');
var LocalStorageStore = /** @class */ (function () {
    function LocalStorageStore(_a) {
        var key = _a.key, _b = _a.maxValues, maxValues = _b === void 0 ? 1000 : _b;
        this.LS_KEY = key;
        this.maxValues = maxValues;
    }
    LocalStorageStore.prototype.get = function (key) {
        return this.getMap()[key] || null;
    };
    LocalStorageStore.prototype.set = function (key, value) {
        var map = this.getMap();
        map[key] = value;
        this.replace(map);
    };
    LocalStorageStore.prototype.remove = function (key) {
        var map = this.getMap();
        delete map[key];
        this.replace(map);
    };
    LocalStorageStore.prototype.values = function () {
        return js_sdk_utils_1.objectValues(this.getMap());
    };
    LocalStorageStore.prototype.clear = function () {
        this.replace({});
    };
    LocalStorageStore.prototype.replace = function (map) {
        try {
            // This is a temporary fix to support React Native which does not have localStorage.
            window.localStorage && localStorage.setItem(this.LS_KEY, JSON.stringify(map));
            this.clean();
        }
        catch (e) {
            logger.error(e);
        }
    };
    LocalStorageStore.prototype.clean = function () {
        var map = this.getMap();
        var keys = Object.keys(map);
        var toRemove = keys.length - this.maxValues;
        if (toRemove < 1) {
            return;
        }
        var entries = keys.map(function (key) { return ({
            key: key,
            value: map[key]
        }); });
        entries.sort(function (a, b) { return a.value.timestamp - b.value.timestamp; });
        for (var i = 0; i < toRemove; i++) {
            delete map[entries[i].key];
        }
        this.replace(map);
    };
    LocalStorageStore.prototype.getMap = function () {
        try {
            // This is a temporary fix to support React Native which does not have localStorage.
            var data = window.localStorage && localStorage.getItem(this.LS_KEY);
            if (data) {
                return JSON.parse(data) || {};
            }
        }
        catch (e) {
            logger.error(e);
        }
        return {};
    };
    return LocalStorageStore;
}());
exports.LocalStorageStore = LocalStorageStore;
