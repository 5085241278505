"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areEventContextsEqual = void 0;
function areEventContextsEqual(eventA, eventB) {
    var contextA = eventA.context;
    var contextB = eventB.context;
    return (contextA.accountId === contextB.accountId &&
        contextA.projectId === contextB.projectId &&
        contextA.clientName === contextB.clientName &&
        contextA.clientVersion === contextB.clientVersion &&
        contextA.revision === contextB.revision &&
        contextA.anonymizeIP === contextB.anonymizeIP &&
        contextA.botFiltering === contextB.botFiltering);
}
exports.areEventContextsEqual = areEventContextsEqual;
