"use strict";
/**
 * Copyright 2019, Optimizely
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultEventQueue = exports.SingleEventQueue = void 0;
var js_sdk_logging_1 = require("@optimizely/js-sdk-logging");
var logger = js_sdk_logging_1.getLogger('EventProcessor');
var Timer = /** @class */ (function () {
    function Timer(_a) {
        var timeout = _a.timeout, callback = _a.callback;
        this.timeout = Math.max(timeout, 0);
        this.callback = callback;
    }
    Timer.prototype.start = function () {
        this.timeoutId = setTimeout(this.callback, this.timeout);
    };
    Timer.prototype.refresh = function () {
        this.stop();
        this.start();
    };
    Timer.prototype.stop = function () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    };
    return Timer;
}());
var SingleEventQueue = /** @class */ (function () {
    function SingleEventQueue(_a) {
        var sink = _a.sink;
        this.sink = sink;
    }
    SingleEventQueue.prototype.start = function () {
        // no-op
    };
    SingleEventQueue.prototype.stop = function () {
        // no-op
        return Promise.resolve();
    };
    SingleEventQueue.prototype.enqueue = function (event) {
        this.sink([event]);
    };
    return SingleEventQueue;
}());
exports.SingleEventQueue = SingleEventQueue;
var DefaultEventQueue = /** @class */ (function () {
    function DefaultEventQueue(_a) {
        var flushInterval = _a.flushInterval, maxQueueSize = _a.maxQueueSize, sink = _a.sink, batchComparator = _a.batchComparator;
        this.buffer = [];
        this.maxQueueSize = Math.max(maxQueueSize, 1);
        this.sink = sink;
        this.batchComparator = batchComparator;
        this.timer = new Timer({
            callback: this.flush.bind(this),
            timeout: flushInterval,
        });
        this.started = false;
    }
    DefaultEventQueue.prototype.start = function () {
        this.started = true;
        // dont start the timer until the first event is enqueued
    };
    DefaultEventQueue.prototype.stop = function () {
        this.started = false;
        var result = this.sink(this.buffer);
        this.buffer = [];
        this.timer.stop();
        return result;
    };
    DefaultEventQueue.prototype.enqueue = function (event) {
        if (!this.started) {
            logger.warn('Queue is stopped, not accepting event');
            return;
        }
        // If new event cannot be included into the current batch, flush so it can
        // be in its own new batch.
        var bufferedEvent = this.buffer[0];
        if (bufferedEvent && !this.batchComparator(bufferedEvent, event)) {
            this.flush();
        }
        // start the timer when the first event is put in
        if (this.buffer.length === 0) {
            this.timer.refresh();
        }
        this.buffer.push(event);
        if (this.buffer.length >= this.maxQueueSize) {
            this.flush();
        }
    };
    DefaultEventQueue.prototype.flush = function () {
        this.sink(this.buffer);
        this.buffer = [];
        this.timer.stop();
    };
    return DefaultEventQueue;
}());
exports.DefaultEventQueue = DefaultEventQueue;
