"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalStoragePendingEventsDispatcher = exports.PendingEventsDispatcher = void 0;
/**
 * Copyright 2019, Optimizely
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var js_sdk_logging_1 = require("@optimizely/js-sdk-logging");
var pendingEventsStore_1 = require("./pendingEventsStore");
var js_sdk_utils_1 = require("@optimizely/js-sdk-utils");
var logger = js_sdk_logging_1.getLogger('EventProcessor');
var PendingEventsDispatcher = /** @class */ (function () {
    function PendingEventsDispatcher(_a) {
        var eventDispatcher = _a.eventDispatcher, store = _a.store;
        this.dispatcher = eventDispatcher;
        this.store = store;
    }
    PendingEventsDispatcher.prototype.dispatchEvent = function (request, callback) {
        this.send({
            uuid: js_sdk_utils_1.generateUUID(),
            timestamp: js_sdk_utils_1.getTimestamp(),
            request: request,
        }, callback);
    };
    PendingEventsDispatcher.prototype.sendPendingEvents = function () {
        var _this = this;
        var pendingEvents = this.store.values();
        logger.debug('Sending %s pending events from previous page', pendingEvents.length);
        pendingEvents.forEach(function (item) {
            try {
                _this.send(item, function () { });
            }
            catch (e) { }
        });
    };
    PendingEventsDispatcher.prototype.send = function (entry, callback) {
        var _this = this;
        this.store.set(entry.uuid, entry);
        this.dispatcher.dispatchEvent(entry.request, function (response) {
            _this.store.remove(entry.uuid);
            callback(response);
        });
    };
    return PendingEventsDispatcher;
}());
exports.PendingEventsDispatcher = PendingEventsDispatcher;
var LocalStoragePendingEventsDispatcher = /** @class */ (function (_super) {
    __extends(LocalStoragePendingEventsDispatcher, _super);
    function LocalStoragePendingEventsDispatcher(_a) {
        var eventDispatcher = _a.eventDispatcher;
        return _super.call(this, {
            eventDispatcher: eventDispatcher,
            store: new pendingEventsStore_1.LocalStorageStore({
                // TODO make this configurable
                maxValues: 100,
                key: 'fs_optly_pending_events',
            }),
        }) || this;
    }
    return LocalStoragePendingEventsDispatcher;
}(PendingEventsDispatcher));
exports.LocalStoragePendingEventsDispatcher = LocalStoragePendingEventsDispatcher;
