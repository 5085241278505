"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendEventNotification = exports.getQueue = exports.validateAndGetBatchSize = exports.validateAndGetFlushInterval = exports.DEFAULT_BATCH_SIZE = exports.DEFAULT_FLUSH_INTERVAL = void 0;
var eventQueue_1 = require("./eventQueue");
var js_sdk_logging_1 = require("@optimizely/js-sdk-logging");
var js_sdk_utils_1 = require("@optimizely/js-sdk-utils");
exports.DEFAULT_FLUSH_INTERVAL = 30000; // Unit is ms - default flush interval is 30s
exports.DEFAULT_BATCH_SIZE = 10;
var logger = js_sdk_logging_1.getLogger('EventProcessor');
function validateAndGetFlushInterval(flushInterval) {
    if (flushInterval <= 0) {
        logger.warn("Invalid flushInterval " + flushInterval + ", defaulting to " + exports.DEFAULT_FLUSH_INTERVAL);
        flushInterval = exports.DEFAULT_FLUSH_INTERVAL;
    }
    return flushInterval;
}
exports.validateAndGetFlushInterval = validateAndGetFlushInterval;
function validateAndGetBatchSize(batchSize) {
    batchSize = Math.floor(batchSize);
    if (batchSize < 1) {
        logger.warn("Invalid batchSize " + batchSize + ", defaulting to " + exports.DEFAULT_BATCH_SIZE);
        batchSize = exports.DEFAULT_BATCH_SIZE;
    }
    batchSize = Math.max(1, batchSize);
    return batchSize;
}
exports.validateAndGetBatchSize = validateAndGetBatchSize;
function getQueue(batchSize, flushInterval, sink, batchComparator) {
    var queue;
    if (batchSize > 1) {
        queue = new eventQueue_1.DefaultEventQueue({
            flushInterval: flushInterval,
            maxQueueSize: batchSize,
            sink: sink,
            batchComparator: batchComparator,
        });
    }
    else {
        queue = new eventQueue_1.SingleEventQueue({ sink: sink });
    }
    return queue;
}
exports.getQueue = getQueue;
function sendEventNotification(notificationCenter, event) {
    if (notificationCenter) {
        notificationCenter.sendNotifications(js_sdk_utils_1.NOTIFICATION_TYPES.LOG_EVENT, event);
    }
}
exports.sendEventNotification = sendEventNotification;
