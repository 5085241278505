function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

/* global analytics */
var config = {
  /* Your segment writeKey */
  writeKey: null,

  /* Disable anonymous MTU */
  disableAnonymousTraffic: false,

  /* Sync segment Anonymous id with `analytics` Anon id */
  syncAnonymousId: false,

  /* Enable/disable segment destinations https://bit.ly/38nRBj3 */
  integrations: {}
  /* Override the Segment snippet url, for loading via custom CDN proxy */

  /**
   * Segment analytics plugin
   * @link https://getanalytics.io/plugins/segment/
   * @link https://segment.com/docs/sources/website/analytics.js/
   * @param {object}  pluginConfig - Plugin settings
   * @param {string}  pluginConfig.writeKey - Your segment writeKey
   * @param {boolean} [pluginConfig.disableAnonymousTraffic] - Disable loading segment for anonymous visitors
   * @param {boolean} [pluginConfig.customScriptSrc] - Override the Segment snippet url, for loading via custom CDN proxy
   * @param {object}  [pluginConfig.integrations] - Enable/disable segment destinations https://bit.ly/38nRBj3
   * @return {object} Analytics plugin
   * @example
   *
   * segmentPlugin({
   *   writeKey: '123-xyz'
   * })
   */

};

function segmentPlugin$1() {
  var pluginConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: 'segment',
    config: _objectSpread({}, config, pluginConfig),

    /* Custom methods to add .group call */
    methods: {
      /* Group https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#group */
      group: function group(groupId) {
        var traits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        var callback = arguments.length > 3 ? arguments[3] : undefined;

        // const analyticsInstance = this.instance
        // If no segment, return early
        if (typeof window.analytics === 'undefined') {
          return;
        } // Make group call to segment


        window.analytics.group(groupId, traits, options, callback);
      }
    },
    bootstrap: function bootstrap(_ref) {
      var config = _ref.config,
          instance = _ref.instance;

      /* Load segment script after userId exists */
      if (config.disableAnonymousTraffic && !instance.user('userId')) {
        instance.once('identifyStart', function (_ref2) {
          var plugins = _ref2.plugins;
          var self = plugins['segment'];

          if (!self.loaded()) {
            instance.loadPlugin('segment');
          }
        });
      }
    },

    /* Load Segment analytics.js on page */
    initialize: function initialize(_ref3) {
      var config = _ref3.config,
          instance = _ref3.instance,
          payload = _ref3.payload;
      var disableAnonymousTraffic = config.disableAnonymousTraffic,
          writeKey = config.writeKey,
          customScriptSrc = config.customScriptSrc;

      if (!writeKey) {
        throw new Error('No segment writeKey');
      }
      /* Disable segment.com if user is not yet identified. Save on Monthly MTU bill $$$ */


      var userID = instance.user('userId');

      if (!userID && disableAnonymousTraffic) {
        return false;
      }
      /* eslint-disable */


      !function () {
        var analytics = window.analytics = window.analytics || [];

        function isScriptLoaded() {
          var scripts = document.getElementsByTagName('script');
          var scriptMatch = customScriptSrc || 'cdn.segment.com/analytics.js/v1/';
          return !!Object.keys(scripts).filter(function (key) {
            var scriptInfo = scripts[key] || {};
            var src = scriptInfo.src || '';
            return src.indexOf(scriptMatch) > -1;
          }).length;
        }

        if (!analytics.initialize) {
          if (!isScriptLoaded()) {
            analytics.invoked = !0;
            analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"];

            analytics.factory = function (t) {
              return function () {
                var e = Array.prototype.slice.call(arguments);
                e.unshift(t);
                analytics.push(e);
                return analytics;
              };
            };

            for (var t = 0; t < analytics.methods.length; t++) {
              var e = analytics.methods[t];
              analytics[e] = analytics.factory(e);
            }

            analytics.load = function (t, e) {
              var n = document.createElement("script");
              n.type = "text/javascript";
              n.async = !0;
              n.src = customScriptSrc || "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
              n.id = 'segment-io';
              var a = document.getElementsByTagName("script")[0];
              a.parentNode.insertBefore(n, a);
              analytics._loadOptions = e;
            };

            analytics.SNIPPET_VERSION = "4.1.0";
            analytics.load(writeKey);
          }
        }
      }();
      /* eslint-enable */
    },

    /* Trigger Segment page view http://bit.ly/2LSPFr1 */
    page: function page(_ref4) {
      var payload = _ref4.payload,
          config = _ref4.config;
      if (typeof analytics === 'undefined') return;
      var properties = payload.properties || {};
      var name = properties.name || properties.title;
      var category = properties.category;
      analytics.page(category, name, properties, _objectSpread({
        integrations: config.integrations
      }, payload.options));
    },

    /* Track Segment event http://bit.ly/2WLnYkK */
    track: function track(_ref5) {
      var payload = _ref5.payload,
          config = _ref5.config;
      if (typeof analytics === 'undefined') return;
      analytics.track(payload.event, payload.properties, _objectSpread({
        integrations: config.integrations
      }, payload.options));
    },

    /* Identify Segment user http://bit.ly/2VL45xD */
    identify: function identify(_ref6) {
      var payload = _ref6.payload,
          config = _ref6.config;
      if (typeof analytics === 'undefined') return;
      var userId = payload.userId,
          traits = payload.traits,
          options = payload.options;

      if (typeof userId === 'string') {
        analytics.identify(userId, traits, _objectSpread({
          integrations: config.integrations
        }, options));
      } else {
        analytics.identify(traits, _objectSpread({
          integrations: config.integrations
        }, options));
      }
    },

    /* Remove segment cookies on analytics.reset */
    reset: function reset() {
      if (typeof analytics === 'undefined') return;
      analytics.reset();
    },

    /* Sync id when ready */
    ready: function ready(_ref7) {
      var instance = _ref7.instance,
          config = _ref7.config;
      if (!config.syncAnonymousId || typeof analytics === 'undefined') return;
      var segmentUser = analytics.user();

      if (segmentUser) {
        var segmentAnonId = segmentUser.anonymousId();
        var analyticsAnonId = instance.user('anonymousId'); // If has segment anonymous ID && doesnt match analytics anon id, update

        if (segmentAnonId && segmentAnonId !== analyticsAnonId) {
          instance.setAnonymousId(segmentAnonId);
        }
      }
    },

    /* Check if segment loaded */
    loaded: function loaded() {
      return window.analytics && !!analytics.initialized;
    }
  };
}

/* This module will shake out unused code + work in browser and node 🎉 */

var index = segmentPlugin$1;

export default index;
